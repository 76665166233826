import { ClassData } from '@models/class-data';
import { Event } from '@models/events/event.model';

export class LandingPageEvent {
  id: number;

  eventId: number;
  event?: Event;

  constructor(json: ClassData<LandingPageEvent>) {
    this.id = +json.id;

    this.eventId = +json.eventId;

    if (json.event) {
      this.event = new Event(json.event);
    }
  }
}
