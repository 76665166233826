import { ClassData } from '@models/class-data';
export class EventCampaignVote {
  id: number;
  eventId?: number;
  campaignId?: number;
  userId?: number;
  ip?: string;
  votedAt?: Date;
  constructor(json: ClassData<EventCampaignVote>) {
    this.id = +json.id;
    if (json.votedAt) {
      this.votedAt = new Date(json.votedAt);
    }
    if (json.eventId !== undefined) {
      this.eventId = +json.eventId;
    }
    if (json.campaignId !== undefined) {
      this.campaignId = +json.campaignId;
    }
    if (json.userId !== undefined) {
      this.userId = +json.userId;
    }
    if (json.ip !== undefined) {
      this.ip = json.ip;
    }
  }
}
