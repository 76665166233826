import { ClassData } from '@models/class-data';

export class EventPayment {
  id: number;

  feeInCents: number;

  venmo?: string;
  cashApp?: string;
  paypal?: string;

  constructor(json: ClassData<EventPayment>) {
    this.id = +json.id;

    this.feeInCents = +json.feeInCents;

    this.venmo = json.venmo;
    this.cashApp = json.cashApp;
    this.paypal = json.paypal;
  }
}
