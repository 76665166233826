import { ClassData } from '@models/class-data';
import { RSVPOptionType } from './rsvp-option-type.enum';

export class EventRSVPOption {
  id: number;

  eventId: number;

  type: RSVPOptionType;

  name: string;
  emoji: string;

  constructor(json: ClassData<EventRSVPOption>) {
    this.id = +json.id;

    this.eventId = +json.eventId;

    this.type = json.type;

    this.name = json.name;
    this.emoji = json.emoji;
  }
}
