import { ClassData } from '@models/class-data';

export class TimezoneOption {
  name: string;
  offset: number;

  constructor(json: ClassData<TimezoneOption>) {
    this.name = json.name;
    this.offset = +json.offset;
  }

  getOffsetDisplay(): string {
    if (!this.offset) {
      return '';
    }

    let utcOffsetHours = (this.offset / 60).toString();

    if (utcOffsetHours[0] !== '-') {
      utcOffsetHours = '+' + utcOffsetHours;
    }

    return utcOffsetHours;
  }

  getDisplayName(): string {
    return this.name.replaceAll('_', ' ');
  }

  getDisplayFnName(): string {
    return `${this.getDisplayName()} (GMT${this.getOffsetDisplay()})`;
  }
}
