import { ClassData } from '@models/class-data';

export class EmailSender {
  id: number;
  userId?: number;
  name?: string;
  email?: string;
  brevoId?: number;
  valid?: boolean;

  constructor(json: ClassData<EmailSender>) {
    this.id = json.id;
    this.userId = json.userId;
    this.name = json.name;
    this.email = json.email;
    this.brevoId = json.brevoId;
    this.valid = json.valid;
  }
}
