export enum EventStatus {
  DRAFT = 'DRAFT',
  ACTIVE_SPECIFIC_DATE = 'ACTIVE_SPECIFIC_DATE',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export namespace EventStatus {
  export function getAllEventStatuses(): EventStatus[] {
    return [
      EventStatus.DRAFT,
      EventStatus.ACTIVE_SPECIFIC_DATE,
      EventStatus.ACTIVE,
      EventStatus.INACTIVE,
      EventStatus.ARCHIVED,
    ];
  }

  export function getDisplayName(status?: EventStatus): string {
    switch (status) {
      case EventStatus.DRAFT:
        return 'APP.EVENT_STATUS.DRAFT';
      case EventStatus.ACTIVE_SPECIFIC_DATE:
        return 'APP.EVENT_STATUS.ACTIVE_SPECIFIC_DATE';
      case EventStatus.ACTIVE:
        return 'APP.EVENT_STATUS.ACTIVE';
      case EventStatus.INACTIVE:
        return 'APP.EVENT_STATUS.INACTIVE';
      case EventStatus.ARCHIVED:
        return 'APP.EVENT_STATUS.ARCHIVED';
      default:
        return '';
    }
  }
}
