export enum EventHostRoleEnum {
  VIEWER = 'VIEWER',
  EDITOR = 'EDITOR',
  CO_HOST = 'CO_HOST',
  CREATOR = 'CREATOR',
}

export namespace EventHostRoleEnum {
  export function getDisplayName(role?: EventHostRoleEnum): string {
    switch (role) {
      case EventHostRoleEnum.VIEWER:
        return 'APP.EVENT.HOST_ROLE.VIEWER';
      case EventHostRoleEnum.EDITOR:
        return 'APP.EVENT.HOST_ROLE.EDITOR';
      case EventHostRoleEnum.CO_HOST:
        return 'APP.EVENT.HOST_ROLE.CO_HOST';
      case EventHostRoleEnum.CREATOR:
        return 'APP.EVENT.HOST_ROLE.CREATOR';
      default:
        return '';
    }
  }
}
