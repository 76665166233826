import { ClassData } from '@models/class-data';
import { User } from '@models/users/user.model';
import { EventHostStatus } from './event-host-status.enum';
import { EventHostRoleEnum } from './event-host-role.enum';

export class EventHost {
  id: number;
  createdAt?: Date;

  userId: number;
  user?: User;

  eventId: number;

  email?: string;
  phone?: string;

  role: EventHostRoleEnum;

  status: EventHostStatus;

  constructor(json: ClassData<EventHost>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.userId = +json.userId;
    if (json.user) {
      this.user = new User(json.user);
    }

    this.eventId = +json.eventId;

    if (json.email) {
      this.email = json.email;
    }

    if (json.phone) {
      this.phone = json.phone;
    }

    this.role = json.role;

    this.status = json.status;
  }

  getName(): string {
    if (!this.user) {
      return '';
    }
    return this.user.name;
  }

  getFallbackInitials(): string {
    if (this.email && this.email !== '') {
      return this.email.trim()[0];
    }

    return '';
  }
}
