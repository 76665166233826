import { EventCampaignVote } from '@models/campaigns/event-campaign-vote.model';
import { ClassData } from '@models/class-data';
import { Campaign } from './campaign.model';
import { EventCampaignCategory } from './event-campaign-category.enum';
import { EventCampaignStatus } from './event-campaign-status.enum';
import { EventCampaignPartyStatus } from './event-campaign-party-status.enum';
import { EventCampaignWorkingStatus } from './event-campaign-working-status.enum';
import { CampaignInsuranceCheckLink } from './campaign-insurance-check-link.model';

export class EventCampaign {
  id: number;

  campaignId: number;
  campaign?: Campaign;

  category?: EventCampaignCategory;
  status?: EventCampaignStatus;
  partyStatus?: EventCampaignPartyStatus;
  workingStatus?: EventCampaignWorkingStatus;
  loggedUserVote?: EventCampaignVote;

  welcomeSeen: boolean;

  confirmNoPackages: boolean;

  campaignInsuranceCheckLinkId?: number;
  campaignInsuranceCheckLink?: CampaignInsuranceCheckLink;

  constructor(json: ClassData<EventCampaign>) {
    this.id = +json.id;

    this.campaignId = +json.campaignId;
    if (json.campaign) {
      this.campaign = new Campaign(json.campaign);
    }
    if (json.loggedUserVote) {
      this.loggedUserVote = new EventCampaignVote(json.loggedUserVote);
    }

    this.category = json.category;
    this.status = json.status;
    this.partyStatus = json.partyStatus;
    this.workingStatus = json.workingStatus;

    this.welcomeSeen = json.welcomeSeen;

    this.confirmNoPackages = json.confirmNoPackages;

    if (json.campaignInsuranceCheckLinkId !== undefined) {
      this.campaignInsuranceCheckLinkId = +json.campaignInsuranceCheckLinkId;
    }
    if (json.campaignInsuranceCheckLink) {
      this.campaignInsuranceCheckLink = json.campaignInsuranceCheckLink;
    }
  }

  isCampaign(uri: string) {
    return this.campaign?.uri === uri;
  }
}
