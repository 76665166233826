import { ClassData } from '@models/class-data';
import { CampaignStatus } from './campaign-status.enum';
import { EventCategory } from '@models/event-categories/event-category.model';
import { TargetGroup } from '@models/shared/target-group.model';
import { Helpers } from '@utils/helpers';

export class Campaign {
  id: number;
  createdAt?: Date;

  status: CampaignStatus;

  name: string;
  uri: string;
  logoUrl: string;
  darkModeLogoUrl: string;

  // old unused values
  enteringScreenTitle: string;
  welcomeScreenHtml: string;

  preselectedEventCategoryId?: number;
  preselectedEventCategory?: EventCategory;

  preselectedTargetGroupId?: number;
  preselectedTargetGroup?: TargetGroup;

  startDate?: Date;
  endDate?: Date;
  daysInFuture?: number;

  minGuests?: number;

  welcomeScreenImageUrl: string;
  welcomeScreenTitle: string;
  welcomeScreenSubtitle: string;
  welcomeScreenText: string;

  allCampaignUsersListId?: number;
  campaignAdsUsersListId?: number;

  accountCreationForVoting: boolean;

  constructor(json: ClassData<Campaign>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.status = json.status;

    this.name = json.name;
    this.uri = json.uri;
    this.logoUrl = json.logoUrl;
    this.darkModeLogoUrl = json.darkModeLogoUrl;

    this.enteringScreenTitle = json.enteringScreenTitle;
    this.welcomeScreenHtml = json.welcomeScreenHtml;

    if (json.preselectedEventCategoryId !== undefined) {
      this.preselectedEventCategoryId = +json.preselectedEventCategoryId;
    }
    if (json.preselectedEventCategory) {
      this.preselectedEventCategory = new EventCategory(
        json.preselectedEventCategory,
      );
    }

    if (json.preselectedTargetGroupId !== undefined) {
      this.preselectedTargetGroupId = +json.preselectedTargetGroupId;
    }
    if (json.preselectedTargetGroup) {
      this.preselectedTargetGroup = json.preselectedTargetGroup;
    }

    if (json.startDate) {
      this.startDate = new Date(json.startDate);
    }
    if (json.endDate) {
      this.endDate = new Date(json.endDate);
    }
    if (json.daysInFuture !== undefined) {
      this.daysInFuture = +json.daysInFuture;
    }

    if (json.minGuests !== undefined) {
      this.minGuests = +json.minGuests;
    }

    this.welcomeScreenImageUrl = json.welcomeScreenImageUrl;
    this.welcomeScreenTitle = json.welcomeScreenTitle;
    this.welcomeScreenSubtitle = json.welcomeScreenSubtitle;
    this.welcomeScreenText = json.welcomeScreenText;

    if (json.allCampaignUsersListId !== undefined) {
      this.allCampaignUsersListId = +json.allCampaignUsersListId;
    }
    if (json.campaignAdsUsersListId !== undefined) {
      this.campaignAdsUsersListId = +json.campaignAdsUsersListId;
    }

    this.accountCreationForVoting = json.accountCreationForVoting;
  }

  getEventParentCategoryId(): number | undefined {
    if (!this.preselectedEventCategory) {
      return undefined;
    }

    return this.preselectedEventCategory.parentCategoryId
      ? this.preselectedEventCategory.parentCategoryId
      : this.preselectedEventCategory.id;
  }

  getEventSubCategoryId(): number | undefined {
    if (!this.preselectedEventCategory) {
      return undefined;
    }

    return this.preselectedEventCategory.parentCategoryId
      ? this.preselectedEventCategory.id
      : undefined;
  }

  hasDatesSet(): boolean {
    return (
      !!this.startDate ||
      !!this.endDate ||
      !!(this.daysInFuture && this.daysInFuture > 0)
    );
  }

  getWelcomeScreenImage(): string {
    if (this.welcomeScreenImageUrl !== '') {
      return this.welcomeScreenImageUrl;
    }

    return this.getLogo();
  }

  getLogo(): string {
    if (Helpers.getTheme() === 'dark' && this.darkModeLogoUrl !== '') {
      return this.darkModeLogoUrl;
    }

    return this.logoUrl;
  }

  minGuestsValid(numberOfGuests: number) {
    if (!this.minGuests) {
      return true;
    }

    return numberOfGuests >= this.minGuests;
  }
}
